<template>
    <div v-wechat-title="this.title" class="home">
        <van-sticky :offset-top="0">
            <div class="page_top">
                <div class="flex_s">
                    <div class="date_choose" v-if="!all">
                        <van-icon name="arrow-left" @click="subtractDate" />
                        <span class="date_info" @click="chooseCheckDay">{{ date || '请选择' }}</span>
                        <van-icon name="arrow" @click="addDate" />
                    </div>
                    <van-search :class="!all?'search_div':'search_div search_div2'" input-align="left" left-icon="" background="#F8F7FC"
                        @search="getListAndCount()" v-model="keyword" placeholder="患者姓名/手机号" />
                    <div class="bt_open" v-if="isOpen" @click="isOpen = !isOpen">隐藏</div>
                    <div class="bt_open" v-else @click="isOpen = !isOpen">展开</div>
                </div>

                <van-dropdown-menu v-if="isOpen" active-color="rgb(230, 159, 18)">
                    <van-dropdown-item @change="getListAndCount()" v-model="visittype" :options="option1" />
                    <van-dropdown-item @change="getListAndCount()" v-model="isskipDispense" :options="option2" />
                    <van-dropdown-item @change="getListAndCount()" v-model="drugtype" :options="option3" />
                    <van-dropdown-item @change="getListAndCount()" v-if="isprocess" v-model="preparation"
                        :options="option4" />
                </van-dropdown-menu>
            </div>
            <van-tabs v-model="qrystatus" v-if="!all" :offset-top="62" @change="getListAndCount()" ref="tabs"
                class="status_div" color="#E69F12" title-active-color="#E69F12" sticky swipeable>
                <!-- 0：待配药，3：待审核，2：待发药，4：加工中，5：已完成，6：加工审核中，7：非本诊所 -->
                <van-tab title="待配药" name="0" :badge="count0">

                </van-tab>

                <van-tab title="待审核" name="3" :badge="count3">

                </van-tab>


                <van-tab v-if="isprocess" title="加工中" name="4" :badge="count4">

                </van-tab>
                <van-tab v-if="isprocess" title="加工审核中" name="6" :badge="count6">

                </van-tab>
                <van-tab title="待发药" name="2" :badge="count2">

                </van-tab>
                <van-tab title="已完成" name="5" :badge="count5">

                </van-tab>
                <van-tab title="非本诊所" name="7" :badge="count7">

                </van-tab>
            </van-tabs>
        </van-sticky>


        <van-calendar v-model="showCalendar" :default-date="defaultDate" :min-date="minDate" :show-confirm="false"
            @confirm="onConfirmCalendar" />


        <div class="treat_main_list">
            <van-list v-model="loading" :finished="finish" @load="getList()" :offset="30" :immediate-check="false"
                v-if="list.length > 0" finished-text="加载完成">
                <div v-for="(item, index) in list" :class="item.isnotice == 0 ? 'highlight_img' : ''" :key="index">
                    <div :class="item.actclinicid != clinicid ? 'one highlight' : 'one'">
                        <div class="name_box">
                            <div class="name">
                                <span class="username">{{ item.customername }}</span>
                                <span v-if="item.seetype == '2'" class="seetypename_f">复</span>
                                <span v-if="item.seetype == '9'" class="seetypename_f">W</span>
                                <span v-if="item.seetype == '1'" class="seetypename_c">初</span>
                                <span v-if="item.seetype != '9' && item.seetype != '2' && item.seetype != '1'"
                                    class="seetypename_f">{{ option1.find((oitme) => oitme.value == item.servicetype).text
                                    }}</span>
                            </div>
                            <div class="ex_box">
                                <div class="drup_info">{{ item.sendtypename }}</div>
                            </div>
                        </div>
                        <div class="name_box line">
                            <div class="flex" style="justify-content: space-between;width: 100%;">
                                <div class="flex">
                                    <div class="user_info">{{ item.sex }}</div>
                                    <div class="user_info">{{ item.age }}岁</div>
                                    <div class="user_info">{{ item.phone }}</div>
                                </div>
                                <div class="appointmentnum" v-if="item.appointmentnum">{{ item.appointmentnum }}</div>
                            </div>
                        </div>
                        <div class="name_box">
                            <div>开单时间</div>
                            <div>{{ item.createtime ? item.createtime : '无' }}</div>
                        </div>
                        <div class="name_box">
                            <div>开单医生</div>
                            <div>{{ item.doctorname }}</div>
                        </div>
                        <div class="name_box">
                            <div>开单诊所</div>
                            <div>{{ item.clinicname }}</div>
                        </div>

                        <div class="flex top10">
                            <!-- 0：待配药，3：待审核，2：待发药，4：加工中，5：已完成，6：加工审核中，7：非本诊所 -->
                            <van-button
                                @click="$router.push({ path: '/dispensingDetails', query: { fid: item.fid, sendstatus: item.sendstatus, date: date } })"
                                round type="success">
                                <span v-if="item.sendstatus == 0">配药</span>
                                <span v-if="item.sendstatus == 3">审核</span>
                                <span v-if="item.sendstatus == 2">发药</span>
                                <span v-if="item.sendstatus == 4">加工</span>
                                <span v-if="item.sendstatus == 5">详情</span>
                                <span v-if="item.sendstatus == 6">加工审核</span>

                                <span v-if="item.sendstatus != 5"> （{{ item.preparationname }}）</span></van-button>
                            <van-button v-if="item.isnotice == 0" @click="operate('NOTICE', item.fids)" round
                                type="success">通知客户取药</van-button>

                        </div>
                    </div>
                </div>
            </van-list>
            <div class="has_no_data" v-else>
                暂无发药信息
            </div>
        </div>
        <div class="incompletecount flex" @click="$router.push({ path: '/alldispensing', query: { all: 1 } })">
            {{ incompletecount }}
        </div>
    </div>
</template>

<script>
import { wxdispensing, wxdispenselist, wxdispensetypecount, wxtriagedictionarydataqry } from '@/api/medical'
import { mapGetters } from 'vuex'
// import Vue from 'vue';
import { Toast } from 'vant';
import Vue from 'vue';
import { Calendar } from 'vant';

Vue.use(Calendar);

export default {
    computed: {
        ...mapGetters([
            'username', 'clinicid', 'isrefresh', 'isclose'
        ]),
    },
    data() {
        return {
            qrystatus: '0',
            list: [],
            // 无限加载
            loading: false,
            count0: 0,
            count3: 0,
            count4: 0,
            count6: 0,
            count2: 0,
            count5: 0,
            count7: 0,
            page: 1,
            isload: false,
            keyword: '',
            notreached: 0,
            wait: 0,
            see: 0,
            finish: false,
            minDate: new Date(2020, 0, 1),
            defaultDate: new Date(),
            date: '',
            showCalendar: false,
            title: '门诊发药',
            visittype: '',
            isskipDispense: '',
            drugtype: '',
            preparation: '',
            all: 0,
            isOpen: 0,
            option1: [
                { text: '全部类型', value: '' },
                { text: '初诊', value: '1' },
                { text: '复诊', value: '2' },
                { text: '网诊', value: '9' },
                { text: '续方', value: '0001' },
                { text: '快速', value: '0013' },
                { text: '领用', value: '0016' },
            ],
            option2: [
                { text: '药品类型', value: '' },
                // { text: '中药饮片', value: 1 },
            ],
            option3: [
                { text: '是否跳过', value: '' },
                { text: '是', value: 1 },
                { text: '否', value: 0 },
            ],
            option4: [
                { text: '制法', value: '' },
            ],
            isprocess: 1,
            incompletecount: 0,
            scrollTop: 0,

        }
    },
    created() {


        this.$store.dispatch('hideOrShowNav', false)

        if (sessionStorage.getItem('date') == '') {
            this.getCurDate()
        }
        this.all = this.$route.query.all ? this.$route.query.all : 0
        if (this.all) {
            this.getList()
        }
        this.getType('DRUP_TYPE', "1,2,3,5,8")
        if (this.isprocess) {
            this.getType('PREPARATION_TYPE', "1,2,3,4,5,6")
        }



    },
    activated() {

        this.$store.dispatch('hideOrShowNav', false)
        let qrystatus = sessionStorage.getItem('qrystatus')
        sessionStorage.setItem('qrystatus', '')
        let date = sessionStorage.getItem('date')
        sessionStorage.setItem('date', '')
        this.isprocess = this.$route.query.isprocess ? this.$route.query.isprocess : 0
        if ((this.isrefresh == '1' && !this.loading) && !date) {
            this.isOpen = false
            this.page = 1;
            this.visittype = '';
            this.isskipDispense = '';
            this.drugtype = '',
                this.qrystatus = 0;
            this.$store.dispatch('changeIsRefresh', '0')
            this.getCurDate()
            this.getListAndCount()
        } else if (qrystatus || qrystatus === '0') {
            this.page = 1
            this.qrystatus = qrystatus
            this.date = date
            this.getListAndCount()

        }
        else {
            if (this.date == '') {
                this.getCurDate()
                this.getListAndCount()
            } else {
                document.getElementsByClassName('home')[0].scrollTop = this.scrollTop;
            }

        }
    },
    beforeRouteLeave(to, from, next) {
        this.scrollTop = document.getElementsByClassName("home")[0].scrollTop;
        next();
    },
    methods: {

        getCurDate() {
            const nowDate = new Date();
            this.formatDate(nowDate)
        },
        addDate() {
            var d = new Date(this.date);
            d.setDate(d.getDate() + 1);
            this.formatDate(d)
            this.getListAndCount()
        },
        subtractDate() {
            var d = new Date(this.date);
            d.setDate(d.getDate() - 1);
            this.formatDate(d)
            this.getListAndCount()
        },
        formatDate(d) {
            const curDate = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                date: d.getDate(),
            }
            const newmonth = curDate.month >= 10 ? curDate.month : '0' + curDate.month
            const day = curDate.date >= 10 ? curDate.date : '0' + curDate.date
            this.date = curDate.year + '-' + newmonth + '-' + day
            this.defaultDate = new Date(this.date);
        },
        chooseCheckDay() {
            this.showCalendar = true
        },
        onConfirmCalendar(date) {
            this.showCalendar = false
            this.finish = false
            this.formatDate(date);
            this.getListAndCount()
        },
        getListAndCount() {
            this.finish = false
            this.page = 1
            this.getList()
            this.getCount()
        },
        getList() {
            // if((this.finish||this.loading)&&this.page != 1){
            //     return;
            // }
            this.loading = true
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            let data = {}
            if (!this.all) {
                data = {
                    username: this.username,
                    clinicid: this.clinicid,
                    qrydate: this.date,
                    isprocess: this.isprocess,
                    preparation: this.preparation,
                    visittype: this.visittype,
                    isskipDispense: this.isskipDispense,
                    customername: this.keyword,
                    drugtype: this.drugtype,
                    qrypage: this.page,
                    iscurclinic: this.qrystatus == 7 ? 0 : 1,
                    status: this.qrystatus
                }
            } else {
                data = {
                    username: this.username,
                    clinicid: this.clinicid,
                    qrydate: '',
                    isprocess: this.isprocess,
                    preparation: this.preparation,
                    visittype: this.visittype,
                    isskipDispense: this.isskipDispense,
                    customername: this.keyword,
                    drugtype: this.drugtype,
                    qrypage: this.page,
                    iscurclinic: this.qrystatus == 7 ? 0 : 1,
                    status: 0
                }
            }
            wxdispenselist(data).then(response => {
                if (this.page == 1) {
                    this.list = []
                    this.list = response.response_body.list
                    document.getElementsByClassName('home')[0].scrollTop = 0;

                } else {
                    this.list = [...this.list, ...response.response_body.list]
                }
                if (response.response_body.list.length < 10) {
                    this.finish = true
                }
                this.page++
                this.loading = false
                Toast.clear()
            }).catch(error => {
                this.loading = false
                Toast.clear()
            })

        },
        getCount() {
            let data = {
                username: this.username,
                clinicid: this.clinicid,
                qrydate: this.date,
                isprocess: this.isprocess,
                preparation: this.preparation,
                visittype: this.visittype,
                isskipDispense: this.isskipDispense,
                customername: this.keyword,
                drugtype: this.drugtype,



            }
            wxdispensetypecount(data).then(response => {
                response.response_body.list.forEach((item) => {

                    if (item.sendstatus == 0) {
                        this.count0 = item.count
                    }
                    if (item.sendstatus == 3) {
                        this.count3 = item.count
                    }
                    if (item.sendstatus == 4) {
                        this.count4 = item.count
                    }
                    if (item.sendstatus == 6) {
                        this.count6 = item.count
                    }
                    if (item.sendstatus == 2) {
                        this.count2 = item.count
                    }
                    if (item.sendstatus == 5) {
                        this.count5 = item.count
                    }
                    if (item.sendstatus == 7) {
                        this.count7 = item.count
                    }

                })

                this.incompletecount = response.response_body.incompletecount
            })
        },
        getType(typeName, itemcode) {
            let data = {
                username: this.username,
                dictcode: typeName,
                itemcode: itemcode
            }
            wxtriagedictionarydataqry(data).then(response => {

                let list = response.response_body.list.map((item) => {

                    return { text: item.itemname, value: item.itemcode }
                })
                if (typeName == 'DRUP_TYPE') {
                    this.option2 = [...this.option2, ...list]
                } else {
                    this.option4 = [...this.option4, ...list]
                }
            })
        },
        operate(type, fids) {
            let myfids = fids.split(",")
            let list = myfids.map((item) => { return { fid: item } })
            let data = {
                username: this.username,
                oprtype: type,
                list
            }

            wxdispensing(data).then(response => {
                Toast.success({
                    message: '通知成功',
                    forbidClick: true,
                })
                this.qrystatus = response.response_body.status
                setTimeout(() => {
                    this.getListAndCount()
                }, 2000)

            })
        }

    },
}
</script>

<style lang="scss" scoped>
.van-info {
    top: 2px;
    right: -8px;
}

.van-cell {
    background-color: #F8F7FC;
    border-radius: 0.50667rem;
}

.bai {
    background-color: #ffffff;
}

.date_choose {
    border: 0.02667rem solid #dfdcdc;
    padding: 10px 2px;
    width: 150px;
}

:deep .van-search__content {
    padding-left: 0rem;
}

.search_div {
    width: 4rem;
}
.search_div2{
    width: 8.2rem !important;
}

:deep .van-dropdown-menu__bar {
    box-shadow: unset;
    height: 1rem;
}

.page_top {
    padding: 0.26667rem;
    display: block;
}

.flex_s {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.bt_open {
    color: #5C7BFF;
    margin-right: 5px;
}

:deep .van-dropdown-item__content {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.home {

    background: #fafafa;
    height: 100vh;
    overflow: auto;
}

.treat_main_list .one {
    background: linear-gradient(180deg, rgba(207, 140, 87, 0.2) 0%, rgba(255, 249, 244, 0) 20%);
}

.name {
    font-size: 18px;
    font-weight: 600;
    display: flex;
}

.appointmentnum {
    color: rgb(230, 159, 18);
    background-color: #fbeec8;
    padding: 2px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 10px;
}

.name_box {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}

.username {
    max-width: 3rem;
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.seetypename_f {
    color: rgb(230, 159, 18);
    padding: 2px;
    border-radius: 5px;
    border: rgb(230, 159, 18) solid 1px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    height: 0.7rem;
}

.seetypename_c {
    color: rgb(133, 142, 228);
    padding: 2px;
    border-radius: 5px;
    border: rgb(133, 142, 228) solid 1px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    height: 0.7rem;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_info {
    color: #999995;
    margin-right: 4px;
    line-height: 20px;
}

.drup_info {
    color: red;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    max-width: 4rem;
    overflow: hidden;
    // height: 0.5rem;
}

:deep .van-button--round {
    background-color: #CF8C57;
    width: max-content;
    height: 38px;
    text-align: center;
    line-height: 38px;
    color: #fff;
    border-radius: 21px;
    margin: 0px 5px;
}

.top10 {
    margin-top: 10px;
}

.highlight {
    background: #e4c1a663 !important;
}

.highlight_img {
    background: url('~@/assets/noNotice_icon.png') no-repeat;
    background-size: 2rem 2rem;
    background-position: 2.33333rem 2.2rem;
}

.incompletecount {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: 200px;
    background: #CF8C57 !important;
    color: #fff;
    border-radius: 3rem;
    font-size: 16px;
    font-weight: 600;

}
</style>